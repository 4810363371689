import React from 'react';

const ChipsRadios = ({i, colors, handleChange}) => {
  
  const handleInput = (ev) => {
    handleChange(ev.target.dataset.id, i)
  }


  return (
    <ul className='answer-chips'>
    {colors?.map((color, index) => 
      <li key={index}>
        <input onInput={handleInput} type="radio" id={"chip-" + i + "-" + index} data-id={index} name={"chips-" + String(i + 1)} value={color} />
        <label htmlFor={"chip-" + i + "-" + index} style={{"backgroundColor":color}}></label>
      </li>
    )}
    </ul>
  );
};

export default ChipsRadios;